import React, { useEffect, useState ,Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate ,Link,useLocation } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
// import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
// import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import {ReactComponent as ShareIcon} from "../../assets/Icons/share.svg";

const ShortsCard = ({ data, metaData, imageUrl ,type,vanityUrl }) => {
  
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [imagURL,setImageURL] = useState(require("../../assets/Images/loading-portrait.gif"))
  const [isImageLoad,setIsImageLoad] = useState(false);
  const [watchlist, setWatchList] = useState(data?.watchlist_flag);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const locationUrl = (window.location.href).split("/")
  const url = locationUrl[0] + "//" + locationUrl[2] + "/shorts/" + vanityUrl

 
    const updateWatchlist = async (flag) => {
    try {
      const response = await updateWatchlistData(appInfo, data?.show_id, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          setWatchList(1);
        } else {
          setWatchList(0);
        }
        if (fetchWatchlistShows) {
          fetchWatchlistShows()
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addToWatchlist = () => {
    if (user) {
      updateWatchlist(1);
    } else {
      navigate("/login", { state: { path: location?.pathname } });
    }
  };

  const removeFromWatchlist = () => {
    updateWatchlist(0);
  };
  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const showHandleClick = () => {
    if(data?.type==="SHORTS"){
      navigate(`/shorts/${data?.vanity_url?data?.vanity_url:data?.event_id}`)
    }
   
    
  }

const replaceImage=(error)=>{
  error.target.src= thumbnailOrientation==='PORTRAIT'? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
}

  const afterImageLoad=(e)=>{
    setIsImageLoad(true)
    let image = data?.logo;
    setImageURL(image)
  }

  return (
    <div className={"shortsCardContainer showContents shortsContainer"} title={data?.show_name || data?.video_title}>
      {/* <ToastContainer /> */}
      <div className="imageContainer">
       
          <img
            className={!isImageLoad?(thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"):"showImage"}
              src={
                imagURL
              }
              key={data?.show_id}
              onError={(e)=>replaceImage(e)}
              onLoad={(e)=>afterImageLoad(e)} 
              alt="ShowImage"
            />
        {
          data?.type === "CONTINUE_WATCHING" &&
        <div className="continueWatchingBar">
          <div className="line" style={{width:data?.watched_percentage <=1?1+"%":data?.watched_percentage+"%"}}></div>
        </div>
        }

        <div className="extraActions">
          <div
            className="showCardOverlay"
          //  onClick={showHandleClick}
          >

          <div className="block-social-info">
            <ul className="music-play-lists">
              {<li className="share">
                 <span className="shareIcon">
                   <ShareIcon/>
                </span>
                <div className="share-wrapper">
                  <div className="share-boxs">
                  
                    <div className="overflow-hidden">
                      <Link to="" target="_blank">
                        <FacebookShareButton url={url} >
                          <i className="fab fa-facebook-f"></i>
                        </FacebookShareButton>
                      </Link>
                      <Link to="" target="_blank">
                        <TwitterShareButton url={url} >
                          <i className="fab fa-twitter"></i>
                        </TwitterShareButton>
                      </Link>
                     
                    </div>

                    <svg
                      width="17"
                      height="40"
                      className="share-shape"
                      viewBox="0 0 15 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transform: 'rotate(90deg)',
                        marginLeft: '29.5%',  
                        marginBottom: '-77%' ,
                        color:'black',
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.8842 40C6.82983 37.2868 1 29.3582 1 20C1 10.6418 6.82983 2.71323 14.8842 0H0V40H14.8842Z"
                        fill="#191919"
                      ></path>
                    </svg>
                  </div>
                  <div className="share-box">

                  </div>
                </div>
              </li>}
            
            </ul>
            <div className="iq-button"  onClick={showHandleClick}>
              <Link
                to={`/shorts/${data.vanity_url}`}
              
                // state={{ data: data, videoDetails: { video_id: data?.video_id,vanity_url:data?.vanity_url }, subscriptionCheck: subscriptionCheck}}
                state={{ data: data, videoDetails: { video_id: data?.video_id,vanity_url:data?.vanity_url },}}
                className="btn d-flex justify-content-center align-items-center text-uppercase position-relative rounded-circle"
              >
                <i className="fa-solid fa-play ms-0"></i>
              </Link>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="metaData">
        <h1 className="title">{data?.show_name || data?.video_title}</h1>
        {metaData ? (
          <>
            <div className="datas">
              <div className="left">
                {
                  data?.year&&
                <span className="year">{data?.year}</span>
                }
                {
                  data?.duration_text&&
                <span className={`duration ${data?.year && 'showDot'}`}>{data?.duration_text}</span>
                }
              </div>
              <div className="right">
                {
                  data?.rating&&
                <span className="certificate">{data?.rating}</span>
                }
              </div>
            </div>
            <div className="genres">
              {/* {
            data?.category_names?.map((item,index)=>(
              <span key={index}>{item}</span>
            ))
          } */}
            </div>
          </>
        ) : (
        null
        )}
      </div>
    </div>
  );
};

export default ShortsCard;
