import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate
} from "react-router-dom";
import CommonPage from "../Components/CommonPage/CommonPage";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderAccountsPage from "../Components/Header/HeaderAccountsPage";
import About from "../Screens/About/About";
import AccountSettings from "../Screens/Accounts/AccountSettings/AccountSetting";
import BillingDetails from "../Screens/Accounts/AccountSettings/BillingDetails";
import StreamingActivity from "../Screens/Accounts/AccountSettings/StreamingActivity";
import ChangePassword from "../Screens/Accounts/ChangePassword/ChangePassword";
import ForgotPassword from "../Screens/Accounts/ForgotPassword/ForgotPassword";
import ResetPassword from "../Screens/Accounts/ForgotPassword/ResetPassword";
import Login from "../Screens/Accounts/Login/Login";
import Register from "../Screens/Accounts/Register/Register";
import ContactSupport from "../Screens/ContactPages/ContactSupport";
import ContactUs from "../Screens/ContactPages/ContactUs";
import Homepage from "../Screens/homepage/Homepage";
import LandingScreeen from "../Screens/LandingScreen/LandingScreeen";
import MyList from "../Screens/MyList/MyList";
import LatestNews from "../Screens/NewsSection/LatestNews";
import NewsDetails from "../Screens/NewsSection/NewsDetails";
import NotFound from "../Screens/NotFound/NotFound";
import PaymentFailed from "../Screens/PaymentStatus/PaymentFailed/PaymetFailed";
import PaymentSuccess from "../Screens/PaymentStatus/PaymentSuccess/PaymentSuccess";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import Search from "../Screens/Search/Search";
import DetailsScreen from "../Screens/ShowDetailsScreen/DetailsScreen";
import RedirectShowCategoryInformation from "../Screens/ShowDetailsScreen/RedirectShowCategoryInformation";

import Payment from "../Screens/Subscription/Payment";
import Subscription from "../Screens/Subscription/Subscription";
import TermsAndConditions from "../Screens/TermsAndConditions/TermsAndConditions";
import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import LinkTvApp from "../Screens/LinkTvApp/LinkTvApp";
import LivePlayer from "../Screens/LivePlayer/LivePlayer";
import PodcastModal from "../Components/Modals/PodcastModal";
import WebViewFailed from "../Screens/PaymentStatus/PaymentFailed/WebViewFailed";
import WebViewSuccess from "../Screens/PaymentStatus/PaymentSuccess/WebViewSuccess";
import AffliatePage from "../Screens/AffliatePage/AffliatePage";
import OpenApp from "../Components/OpenApp/OpenApp";
import { checkOperatingSystem } from "../utils/utils";
import LiveChannels from "../Screens/LiveChannels/LiveChannels";
import AffliateTestPage from "../AffliateTestPage";
import LanguageModal from "../Components/Modals/LanguageModal";
import VerifyApp from "../Screens/VerifyApp/VerifyApp";
import { affliateAnalytics } from "../network/service";
import EventDetailsScreen from "../Screens/EventDetails/EventDetailsScreen";
import Videoplayer from "../Screens/videoPlayer/VideoPlayer";
import { getLanguageModal } from "../Redux/LanguageModal/languageModalSlice";
import LoginRequest from "../Screens/LoginRequest/LoginRequest";
import TVAppIndex from "../Screens/LinkTvApp/TVAppIndex";
import B2BDetailScreen from "../Screens/B2BDetailsScreen/B2BDetailsScreen";
import Playlist from "../Screens/PlaylistPage/Playlist";
import ProgressBar from "../Components/Modals/LoginModal/ProgressBarModal";
import HowToUpdate from "../Screens/HowToUpdatePage/HowToUpdate";
import Trailer from "../Screens/Trailer/Trailer";
import LivePlayerPage from "../Screens/LivePlayerPage/LivePlayerPage";
import Shorts from "../Screens/Shorts/Shorts";
const LayoutsRoutes = () => {

  const location = useLocation();
  const [initialPage, setInitialPage] = useState("");
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const podcastModal = useSelector((state) => state?.podcastModal?.value);
  const downloadB2bModal = useSelector((state) => state?.downloadB2bModal?.value);

  const urlParams = new URLSearchParams(window.location.search);
  const affliateId = localStorage?.getItem("affliateId");
  const navigate = useNavigate();
  const [isOpenAppFeature, setIsOpenAppFeature] = useState(false);
  const [isOpenAppFeatureClosed, setIsOpenFeatureClosed] = useState(false);
  const androidToken = urlParams.get("antkn");

  const appInfo = {
    projectDetails: projectInfo,
  };

  useEffect(() => {
    if (!androidToken) {
      let openAppFeatureNotNeededRoutes = ["/privacy-policy", "/terms-and-conditions", "/subscription", "/payment", "success", "/failed", "/404"]
      if (
        (checkOperatingSystem() === "android" ||
          checkOperatingSystem() === "iPhone") &&
        !openAppFeatureNotNeededRoutes?.includes(location?.pathname)
        &&
        projectInfo?.projectConfig?.config?.DEEP_LINKING_ENABLED === "true"
        &&
        isOpenAppFeatureClosed === false
      ) {
        setIsOpenAppFeature(true);
      } else {
        setIsOpenAppFeature(false)
      }
    }
  }, [androidToken, location]);
  useEffect(() => {
    if (affliateId) {
      if (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone") {
        navigate("/");
      } else {
        setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);
        updateAffliateAnalytics()
      }
    }
    else {
      setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);

    }
  }, []);

  const updateAffliateAnalytics = async () => {
    try {
      const response = await affliateAnalytics(appInfo);
      if (response.status === 200) {
        localStorage.removeItem("affliateId");
        localStorage.removeItem("affliateUrl");
        navigate(location?.pathname)

      }
    } catch (err) {
      localStorage.removeItem("affliateId");
      localStorage.removeItem("affliateUrl");
    }
  }
  if (affliateId && (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone")) {
    return (
      <Routes>
        <Route path="/">
          <Route path="/" element={<Navigate to="/redirect" />} />
        </Route>
        <Route path="/redirect" element={<AffliatePage />} />
      </Routes>
    );
  }
  else {
    return (
      initialPage && (
        <>
          {projectInfo?.projectConfig?.config?.WEBSITE_NOT_NEEDED !== "true" && location?.pathname !== "/login" &&
            location?.pathname !== "/register" &&
            location?.pathname !== "/forgot-password" &&
            location?.pathname !== "/forgot-password/reset"
            ? initialPage !== "AFFLIATEPAGE" && <Header />
            : initialPage !== "AFFLIATEPAGE" && projectInfo?.projectConfig?.config?.WEBSITE_NOT_NEEDED !== "true" && <HeaderAccountsPage />}
          {podcastModal?.isOpen && <PodcastModal data={podcastModal?.data} />}


          {isOpenAppFeature && (
            <OpenApp setIsOpenAppFeature={setIsOpenAppFeature} setIsOpenFeatureClosed={setIsOpenFeatureClosed} />
          )}

          <Routes>
            {/* for which page need to show initial */}
            <Route path="/">
              {

                initialPage === "LANDING" ? (
                  <Route path="/" element={<LandingScreeen />} />
                ) : initialPage === "LOGIN" ? (
                  <Route path="/" element={<Navigate to="/login" />} />
                ) : initialPage === "HOME" ? (
                  <Route path="/" element={<Navigate to="/home" />} />
                ) : null}
            </Route>

            {/* <Route element={<PublicRoutes />}> */}
            <Route path="/videos/:videoId" element={<Videoplayer />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/loginRequest" element={<LoginRequest />} />
            <Route path="/trailer/:vanity" element={<Trailer/>} />
            {/* </Route> */}
            <Route path="/language-selection" element={<LanguageModal type="root" />} />

            <Route element={<PrivateRoutes />}>
              <Route path="/sample" element={<AffliateTestPage />} />
              <Route path="/verify-app" element={<VerifyApp />} />

              <Route path="/home" element={<Homepage />} />
              <Route path="/:name" element={<RedirectShowCategoryInformation />} />
              <Route path="/show-details/:name" element={<DetailsScreen />} />
              <Route path="/event/:eventId" element={<EventDetailsScreen />} />
              <Route path="/category/:careers" element={<CommonPage />} />
              <Route path="/search" element={<Search />} />
              <Route path="/how-to-update" element={<HowToUpdate/>} />
              <Route
                path="/forgot-password/reset/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/contact-support" element={<ContactSupport />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/news" element={<LatestNews />} />
              <Route path="/news-info/:newsId" element={<NewsDetails />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/redirect" element={<Navigate to="/home" />} />
              <Route path="/affliate" element={<AffliatePage />} />
              <Route path="/live-channels" element={<LiveChannels />} />
              {/* <Route path="/player" element={<LivePlayer />} /> */}
              <Route path="/videos/:videoId" element={<Videoplayer />} />
              <Route path="/playlist/:id" element={<Playlist />} />
              <Route path="/B2B/:name" element={<B2BDetailScreen />} />
              <Route  path="/live-player" element ={<LivePlayerPage/>} />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/failed" element={<PaymentFailed />} />
              <Route path="/webviewfailed" element={<WebViewFailed />} />
              <Route path="/success" element={<PaymentSuccess />} />
              <Route path="/webviewsuccess" element={<WebViewSuccess />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/account" element={<AccountSettings />} />
              <Route path="/shorts" element={<Shorts />} />
              <Route path="/shorts/:vanityUrl" element={<Shorts />} />
              <Route
                path="account/billing-activity"
                element={<BillingDetails />}
              />
              <Route
                path="account/streaming-activity"
                element={<StreamingActivity />}
              />
              <Route path="/payment" element={<Payment />} />
              <Route path="/my-list" element={<MyList />} />
              <Route path="/tv" element={<TVAppIndex />} />
            </Route>

            <Route path="*" element={<Navigate to="/404" />}></Route>
            <Route path="/404" element={<NotFound />} />
          </Routes>
          {
            downloadB2bModal?.downloadProgress > 0 && !downloadB2bModal?.showDownload && <ProgressBar downloadB2bModal={downloadB2bModal} />
          }
          {initialPage !== "AFFLIATEPAGE" && projectInfo?.projectConfig?.config?.WEBSITE_NOT_NEEDED !== "true" && <Footer />}
        </>
      )
    );
  }
};

export default LayoutsRoutes;